import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
    getAdvancedSettings,
    updateAdvancedSettings,

} from "../../../actions/adminConsole";
import { Header, Grid, Radio, Button } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

export const AdvancedSettings = ({
    updateAdvancedSettings,
    advancedSettings,
}) => {
    const { t } = useTranslation();

    const [maximizeGreenZone, setMaximizeGreenZone] = useState(Object.keys(advancedSettings).length === 0 ? false : advancedSettings.advancedSettings.maximize_green_zone)

    const submitAdvancedSettigs = () => {
        updateAdvancedSettings({ maximize_green_zone: maximizeGreenZone })
    }

    return (
            <Grid padded={"horizontally"}>
                
                <Grid.Row>
            <Header className="create-workspace-header">{t("account.adminConsole.advancedSettings")}</Header>
            </Grid.Row>

            <Grid.Row columns={2}>
                    <Grid.Column textAlign="left" width={3}>
                    <label >{t("account.adminConsole.maximizeGreenZone")}</label>
                    </Grid.Column>
                    <Grid.Column textAlign="left">
                    <Radio
                        toggle
                        checked={maximizeGreenZone}
                        onChange={() => {
                            setMaximizeGreenZone(!maximizeGreenZone)
                        }}
                    />
                    </Grid.Column>
            </Grid.Row>
            <Grid.Row >
                <Grid.Column textAlign="left">
                    <Button
                        positive
                        size="small"
                        disabled={maximizeGreenZone === advancedSettings.advancedSettings.maximize_green_zone}
                        onClick={() => submitAdvancedSettigs()}
                        className="workspace-row-left-button"
                    >
                        {t("save")}
                    </Button>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )

}

AdvancedSettings.propTypes = {
    advancedSettings: PropTypes.object,
    getAdvancedSettings: PropTypes.func,
    updateAdvancedSettings: PropTypes.func,
};

AdvancedSettings.defaultProps = {
    advancedSettings: {},
};

const mapStateToProps = (state) => ({
    advancedSettings: state.adminConsole.advancedSettings,

});
export default connect(mapStateToProps, {
    getAdvancedSettings,
    updateAdvancedSettings,
})(AdvancedSettings);